import { ref } from 'vue';
import MyPopup from '@/components/MyPopup/index.vue';
import termsUse from './index.js';
import { useI18n } from 'vue-i18n';
import { getMaxZIndex } from '@/utils/index';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  setup(__props, {
    expose
  }) {
    var _t;
    /** ***引入相关包start*****/
    const {
      t
    } = useI18n();
    const show = ref(false);
    const str = (_t = t('logAndReg.register.tips15')) === null || _t === void 0 ? void 0 : _t.split('\\n');
    expose({
      show
    });
    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/

    /** ***函数 end*****/

    /** ***生命周期start*****/

    /** ***生命周期end*****/

    const __returned__ = {
      t,
      show,
      str,
      ref,
      MyPopup,
      termsUse,
      useI18n,
      getMaxZIndex
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};