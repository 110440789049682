import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { reactive, ref, onMounted, getCurrentInstance } from 'vue';
import { useI18n } from 'vue-i18n';
import useFreeWithdraw from '@/store/modules/freeWithdraw.js';
import useUserInfoStore from '@/store/modules/userInfo.js';
import showFreeWithdrawSharePopup from '@/views/activity/events/details/components/FreeWithdraw/SharePopup/index.js';
import showFreeWithdrawSuccess from '@/views/activity/events/details/components/FreeWithdraw/WithdrawSuccess/index.js';
import { freeWithdrawActivityRecordsApi, getActivityProgress } from '@/api/FreeWithdraw/index.js';
import useAppStore from '@/store/modules/app';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/
// 获取全局组件实例

export default {
  props: {
    // 剩余次数
    remainCount: {
      type: [Number, String],
      default: 0
    },
    ms: {
      type: [null, Number],
      default: null
    },
    type: {
      type: Number,
      default: 0
    }
  },
  emits: ['end', 'changeBg'],
  setup(__props, {
    expose,
    emit
  }) {
    expose();
    const props = __props;
    /** ***引入相关包start*****/
    const {
      proxy
    } = getCurrentInstance();
    const freeWithdrawStore = useFreeWithdraw();
    const {
      t
    } = useI18n();
    const appStore = useAppStore();
    const tigerGame = reactive({
      list: [{
        icon: 'reject',
        value: 0
      }, {
        text: t('FreeWithdrawal.withdrawal'),
        icon: 'resolve',
        value: 3
      }, {
        icon: 'resolve',
        text: '50',
        value: 2
      }, {
        icon: 'padding',
        value: 1
      }, {
        icon: 'resolve',
        text: '1',
        value: 2
      }, {
        text: '10',
        value: 2
      }, {
        icon: 'padding',
        value: 1
      }, {
        icon: 'resolve',
        text: '30',
        value: 2
      }],
      activeIndex: 1,
      prizeAmount: 0,
      // 中将金额
      gameInProgress: false,
      // 游戏是否在进行中
      loading: false
    });
    const randomDeg = ref(0); // 随机旋转的圈数角度
    const lastDeg = ref(0); // 上次结束的角度
    const isOpens = ref(false);
    const reward = ref(0); // 中奖金额
    const activeArr = ref(null);
    const receiveStatus = ref(0);
    const isPcSmall = ref(false); //pc小转盘
    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/

    const getAngle = index => {
      const angle = 360 / tigerGame.list.length;
      const startDeg = -(angle / 2);
      const rotateDeg = startDeg + angle * index;
      return `rotate(${rotateDeg + 22.5}deg)`;
    };
    const getAngleItemRolate = index => {
      const angle = 360 / tigerGame.list.length;
      const startDeg = angle / 2;
      return `rotate(${-startDeg - 44}deg)`;
    };

    // 开始游戏
    const handleStartGame = async () => {
      if (!useUserInfoStore().token) {
        // 要登录才能开始游戏
        proxy.$showLogin({
          type: 'login'
        }).catch(error => {
          console.log(error);
        });
        freeWithdrawStore.setLoginInProress(true);
        return;
      }
      if (freeWithdrawStore.attendStatus === '3') {
        showFreeWithdrawSuccess();
        return;
      }

      // 如果游戏在进行中或者游戏剩余次数小于1就return

      if (tigerGame.gameInProgress) return;
      if (props.remainCount < 1) {
        // showTimeTipsPopup()
        showFreeWithdrawSharePopup();
        return;
      }
      if (!props.ms) {
        isOpens.value = true;
      }
      if (!props.type && appStore.device === 'desktop') {
        isPcSmall.value = true;
        emit('changeBg');
      }
      try {
        if (!tigerGame.loading) {
          tigerGame.loading = true;
          const {
            data
          } = await getActivityProgress();
          if (data) {
            receiveStatus.value = data.receiveStatus;
            if (data.receiveStatus === 3) {
              return;
            }
          }
          tigerGame.prizeAmount = await freeWithdrawStore.freeWithdrawTurn();
          const deg = getNumber(reward.value);
          ratating(deg);
          //未参加更新参加活动的状态
          if (freeWithdrawStore.attendStatus === '1') freeWithdrawStore.freeWithdrawActivityExecute();
        }
      } catch (error) {
        console.error(error);
      }
    };
    // 执行获取游戏动画选中的下标

    const getRandom = (n, m) => {
      const result = Math.floor(Math.floor(Math.random() * (m - n + 1) + n));
      return result;
    };
    const getNumber = num => {
      // 获取中奖值对应的下标数组
      const indexArr = [];
      tigerGame.list.forEach((x, i) => {
        if (tigerGame.prizeAmount === 0 ? x.value === 0 : x.value === 1) {
          indexArr.push(i);
        }
      });
      activeArr.value = indexArr;
      // 随机获取一个中奖下标数组的下标
      const caseNum = getRandom(0, indexArr.length - 1);
      // 获取随机到的中奖下标
      const caseIndex = indexArr[caseNum];
      const angle = 360 / tigerGame.list.length;
      return 360 - angle * (caseIndex + 1);
    };
    // 执行动画帧请求

    const ratating = deg => {
      return new Promise(resolve => {
        let activeIndex = 0;
        const activeArr = [];
        tigerGame.list.forEach((x, i) => {
          // tigerGame.prizeAmount === 0 表示不中奖，其他表示中将
          if (tigerGame.prizeAmount === 0 ? x.value === 0 : x.value === 1) {
            activeArr.push(i);
          }
        });
        // 从可以选中的下标随机取一个
        activeIndex = activeArr[Math.floor(Math.random() * activeArr.length)];
        randomDeg.value = activeArr.length === 1 ? deg + 1800 * (activeIndex + 1) : deg + 360 * activeIndex;
        const realDeg = 360 - lastDeg.value % 360 + lastDeg.value + randomDeg.value;
        const ele = document.querySelector(!props.type ? '.is_small.turnable_ul' : '.is_big.turnable_ul');
        ele.style.transform = `rotate(${realDeg + 22.5}deg)`;
        setTimeout(() => {
          lastDeg.value = realDeg;
          emit('end', {
            prizeAmount: tigerGame.prizeAmount
          });
          tigerGame.loading = false;
        }, 4000);
      });
    };
    /** ***函数 end*****/

    /** ***生命周期start*****/
    onMounted(async () => {
      const res = await freeWithdrawActivityRecordsApi();
      let key = 0;
      tigerGame.list.forEach((x, index) => {
        if (x.value === 2) {
          tigerGame.list[index].text = res.data[key].amount;
          key++;
        }
      });
    });
    /** ***生命周期end*****/

    const __returned__ = {
      proxy,
      freeWithdrawStore,
      emit,
      t,
      appStore,
      props,
      tigerGame,
      randomDeg,
      lastDeg,
      isOpens,
      reward,
      activeArr,
      receiveStatus,
      isPcSmall,
      getAngle,
      getAngleItemRolate,
      handleStartGame,
      getRandom,
      getNumber,
      ratating,
      reactive,
      ref,
      onMounted,
      getCurrentInstance,
      useI18n,
      useFreeWithdraw,
      useUserInfoStore,
      showFreeWithdrawSharePopup,
      showFreeWithdrawSuccess,
      freeWithdrawActivityRecordsApi,
      getActivityProgress,
      useAppStore
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};