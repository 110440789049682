import _objectSpread from "D:/new-pg/brlwon7-site1/node_modules/.pnpm/registry.npmmirror.com+@babel+runtime@7.27.0/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { reactive, ref, onMounted, defineAsyncComponent } from 'vue';
import MyPopup from '@/components/MyPopup/index.vue';
import MyTabs from '@/components/MyTabs/index.vue';
import SelectAmount from './components/SelectAmount.vue';
import Bubbles from './components/bubbles.vue';
import rechargeRecordsPopup from '../rechargeRecordsPopup/index.js';
import rechargePopup from './index.js';
import { rechargeLimitApi, rechargeConfigListApi, payApi } from '@/api/recharge/recharge.js';
import { myFixed, myWindowOpen } from '@/utils/index.js';
import { addToCart_Buried } from '@/utils/buried.js';
import useAppStore from '@/store/modules/app.js';
import useUserInfoStore from '@/store/modules/userInfo';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import payMethods from './components/payMethods/index';
// import { handleCPF } from '@/utils/index.js'
// import showRechargeTips from '@/componentsFun/MySecondPopup/index'
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  setup(__props, {
    expose
  }) {
    /** ***引入相关包start*****/
    const {
      t
    } = useI18n();
    const appStore = useAppStore();
    const userInfo = useUserInfoStore();
    const show = ref(false);
    const CO = defineAsyncComponent(() => import('../esCO/rechargePopup/index'));
    const CL = defineAsyncComponent(() => import('../esCL/rechargePopup/index'));
    const EC = defineAsyncComponent(() => import('../esEC/rechargePopup/index'));
    const MX = defineAsyncComponent(() => import('../esMX/rechargePopup/index'));
    const PE = defineAsyncComponent(() => import('../esPE/rechargePopup/index'));
    const rechargeMap = {
      CO: CO,
      CL: CL,
      EC: EC,
      MX: MX,
      PE: PE
    };
    expose({
      show
    });
    // 初始化加载是否显示
    const initLoadingShow = ref(false);
    // 充值按钮加载是否显示
    const submitLoadingShow = ref(false);
    const state = reactive({
      amountsList: [],
      // 充值金额列表
      amountsListActive: -1,
      // 充值金额选中的金额项
      limit: {},
      // 充值的条件限制
      showRechargeFlag: false,
      amountValue: '',
      //充值金额
      activities: [],
      isChecked: false,
      // 是否参与活动
      selectData: {} // 所选充值金额对象
    });
    const timestamp = ref();

    // 线上支付
    const tabs = reactive({
      list: [{
        text: t('recharge.depositOnline'),
        value: '0'
      }],
      activeIndex: 0
    });

    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/
    const toMessage = () => {
      router.push({
        path: '/message',
        query: {
          current: 1
        }
      });
      unMountComponent();
    };

    // 选择金额
    const handleSelectAmountChange = (index, item) => {
      state.amountsListActive = index;
      state.selectData = item;
      state.amountValue = item.amount;
    };

    //是否参加活动
    const changeChecked = val => {
      state.isChecked = val;
    };

    // 初始化加载接口
    const init = () => {
      initLoadingShow.value = true;
      Promise.all([rechargeLimitHttp(), rechargeConfigListHttp()]).then(res => {
        let [rechargeLimit, rechargeConfigList] = res;
        state.limit = rechargeLimit.data;
        const {
          activityDataArr: activities,
          amountList,
          rechargeConfig
        } = rechargeConfigList.data;
        state.isChecked = rechargeConfig.joinActivitySwitchIsChecked; //默认是否选中
        state.activities = activities;
        state.amountsList = amountList.map(amount => ({
          // isChecked: false, // 是否勾选
          type: null,
          // 活动类型
          amount: Number(amount),
          // 充值金额
          totalProfit: '' // 总收益
        }));
        initAmountsList(amountList, activities);
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        initLoadingShow.value = false;
      });
    };
    // 充值最大最小还有cpf等接口数据
    const rechargeLimitHttp = async () => {
      return rechargeLimitApi();
    };
    // 充值金额列表和活动的数据
    const rechargeConfigListHttp = async () => {
      return rechargeConfigListApi();
    };
    /**
     * 返回符合 一次性充值活动 的数据
     * @param {*} amount
     * @param {*} type - '10'
     * @param {*} oneTimeData
     */
    const initOneTimeActivity = (amount, type, oneTimeData, activityId) => {
      let result = null;
      const {
        maxGiftAmount,
        giftUnit,
        giftAmount,
        minRechargeAmount,
        maxRechargeAmount
      } = oneTimeData;
      if (amount >= minRechargeAmount && !maxRechargeAmount || amount >= minRechargeAmount && amount <= maxRechargeAmount) {
        // 总收益
        let totalProfit = myFixed(giftUnit === '0' ? amount * (giftAmount / 100) : giftAmount);
        if (totalProfit > maxGiftAmount) totalProfit = maxGiftAmount;
        result = {
          // isChecked: false, // 是否勾选
          type,
          // 活动类型
          activityId,
          // 活动文章id
          unit: giftUnit,
          // 百分比还是真实金额
          amount,
          // 充值金额
          totalProfit: totalProfit,
          // 总收益
          giftAmount
        };
      }
      return result;
    };

    /**
     * 返回符合 阶梯充值活动 的数据
     * @param {*} amount
     * @param {*} type - '11'
     * @param {*} stepDataList
     */
    const initStepActivity = (amount, type, stepDataList, activityId) => {
      let result = {
        // isChecked: state.isChecked, // 是否勾选
        type,
        // 活动类型
        activityId,
        // 活动文章id
        unit: '',
        // 百分比还是真实金额
        amount,
        // 充值金额
        totalProfit: '',
        // 总收益
        giftAmount: ''
      };
      stepDataList.forEach(({
        minRechargeAmount,
        maxRechargeAmount,
        giftAmount,
        giftUnit,
        maxGiftAmount
      }) => {
        if (amount >= minRechargeAmount && !maxRechargeAmount || amount >= minRechargeAmount && amount <= maxRechargeAmount) {
          let totalProfit = myFixed(giftUnit === '0' ? amount * (giftAmount / 100) : giftAmount);
          if (totalProfit > maxGiftAmount) totalProfit = maxGiftAmount;
          result = {
            // isChecked: state.isChecked, // 是否勾选
            type,
            // 活动类型
            activityId,
            // 活动文章id
            unit: giftUnit,
            // 百分比还是真实金额
            amount,
            // 充值金额
            totalProfit: totalProfit,
            // 总收益
            giftAmount
          };
        }
      });
      return result;
    };

    /**
     * 返回符合 固定额度充值 的数据
     * @param {*} amount
     * @param {*} type - '14'
     * @param {*} constantData
     */
    const initFixedAmountActivity = (amount, type, constantData, activityId) => {
      let result = null;
      const {
        constantAmountList,
        showTimeLimit,
        timeLimitSecond
      } = constantData;
      if (!timestamp.value) timestamp.value = timeLimitSecond * 1000;

      // 找到amount金额所在位置，并且type未被赋值的数据，重新赋值
      // type有值，说明这个金额已经参与优先级比较高的活动
      constantAmountList.forEach(item => {
        if (Number(item.recharge) === amount) {
          result = {
            // isChecked: false, // 是否勾选
            type,
            // 活动类型
            isShowTimerPanel: showTimeLimit,
            activityId,
            // 活动文章id
            amount: Number(item.recharge),
            // 充值金额
            totalProfit: Number(item.reward) // 总收益
          };
        }
      });
      return result;
    };

    /**
     * 返回符合 充值理财活动 的数据
     * @param {*} amount
     * @param {*} type - '15'
     * @param {*} timeLimitSecond
     * @param {*} showTimeLimit
     * @param {*} financeList
     */
    const initFinanceActivity = (amount, type, timeLimitSecond, showTimeLimit, financeList, activityId) => {
      let result = null;
      if (!timestamp.value) timestamp.value = timeLimitSecond * 1000;

      // 找到amount金额所在位置，并且type未被赋值的数据，重新赋值
      // type有值，说明这个金额已经参与优先级比较高的活动
      financeList.forEach(({
        days,
        investAmount,
        totalRevenue: totalProfit,
        revenueRate
      }) => {
        if (investAmount === amount) {
          result = {
            // isChecked: false, // 是否勾选
            isShowTimerPanel: showTimeLimit,
            type,
            // 活动类型
            activityId,
            // 活动文章id
            days,
            // 活动总天数
            amount: investAmount,
            // 充值金额
            totalProfit: totalProfit,
            // 总收益
            revenueRate // 收益率
          };
        }
      });
      return result;
    };

    /**
     * 初始化 '一次性充值活动' type: 10 金额列表
     * @param {*} amount - 当前处理的金额
     * @param {*} activity - 活动数据
     */
    const oneTimeActivity = (amount, {
      activityType: type,
      oneTimeData,
      activityId
    }) => {
      const index = state.amountsList.findIndex(item => item.amount === amount);

      // 找到amount金额所在位置，并且type未被赋值的数据，重新赋值
      // type有值，说明这个金额已经参与优先级比较高的活动
      if (index !== -1 && !state.amountsList[index].type) {
        const amountData = initOneTimeActivity(amount, type, oneTimeData, activityId);
        if (amountData) state.amountsList[index] = amountData;
      }
    };

    /**
     * 初始化 '阶梯充值活动' type: 11 金额列表
     * @param {*} amount
     * @param {*} param1
     */
    const stepActivity = (amount, {
      activityType: type,
      stepDataList,
      activityId
    }) => {
      const index = state.amountsList.findIndex(item => item.amount === amount);

      // 找到amount金额所在位置，并且type未被赋值的数据，重新赋值
      // type有值，说明这个金额已经参与优先级比较高的活动
      if (index !== -1 && !state.amountsList[index].type) {
        const amountData = initStepActivity(amount, type, stepDataList, activityId);
        if (amountData) state.amountsList[index] = amountData;
      }
    };

    /**
     * 初始化 '固定额度充值活动' type: 14 金额列表
     * @param {*} amount
     * @param {*} param1
     */
    const fixedAmountActivity = (amount, {
      activityType: type,
      constantData,
      activityId
    }) => {
      const index = state.amountsList.findIndex(item => item.amount === amount);

      // 找到amount金额所在位置，并且type未被赋值的数据，重新赋值
      // type有值，说明这个金额已经参与优先级比较高的活动
      if (index !== -1 && !state.amountsList[index].type) {
        const amountData = initFixedAmountActivity(amount, type, constantData, activityId);
        if (amountData) state.amountsList[index] = amountData;
      }
    };

    /**
     * 初始化 '充值理财活动' type: 15 金额列表
     * @param {*} amount
     * @param {*} param1
     */
    const financeActivity = (amount, {
      activityType: type,
      timeLimitSecond,
      showTimeLimit,
      financeList,
      activityId
    }) => {
      const index = state.amountsList.findIndex(item => item.amount === amount);

      // 找到amount金额所在位置，并且type未被赋值的数据，重新赋值
      // type有值，说明这个金额已经参与优先级比较高的活动
      if (index !== -1 && !state.amountsList[index].type) {
        const amountData = initFinanceActivity(amount, type, timeLimitSecond, showTimeLimit, financeList, activityId);
        if (amountData) state.amountsList[index] = amountData;
      }
    };

    /**
     * 初始化金额列表
     * @param {*} amountsList - 快捷金额列表
     * @param {*} activities - 所有活动列表
     */
    const initAmountsList = (amountsList, activities) => {
      amountsList.forEach(amount => {
        activities.forEach(activity => {
          switch (activity.activityType) {
            case '10':
              // 一次性充值活动
              oneTimeActivity(Number(amount), activity);
              break;
            case '11':
              // 阶梯充值活动
              stepActivity(Number(amount), activity);
              break;
            case '14':
              // 固额充值活动
              fixedAmountActivity(Number(amount), activity);
              break;
            case '15':
              // 充值理财活动
              financeActivity(Number(amount), activity);
              break;
            default:
          }
        });
      });
    };

    // // 销毁组件
    // const unMountTips = () => {
    //   state.showRechargeFlag = true
    //   showRechargeTips({ type: 'unmount' })
    // }

    // 提交充值
    const handleSubmit = async params => {
      // unMountTips()
      submitLoadingShow.value = true;
      try {
        const {
          code,
          data
        } = await payApi(_objectSpread({}, params));
        if (code === 200) {
          let resData;
          if (data.indexOf('url') !== -1) {
            var _JSON$parse;
            resData = (_JSON$parse = JSON.parse(data)) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.url;
          } else {
            resData = data;
          }
          // 埋点-充值
          addToCart_Buried(Number(state.amountValue), 'BLR');
          unMountComponent();
          if (data.indexOf('http') !== -1) {
            myWindowOpen(resData);
          } else {
            payMethods({
              props: {
                amount: Number(state.amountValue),
                imageData: resData
              }
            });
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setTimeout(() => {
          submitLoadingShow.value = false;
        }, 350);
      }
    };

    // 销毁组件
    const unMountComponent = () => {
      rechargePopup({
        type: 'unmount'
      });
    };
    const changeAmount = (newValue, oldValue) => {
      if (newValue !== oldValue) {
        const amount = Number(newValue);
        const index = state.amountsList.findIndex(item => item.amount === amount);
        let selectData = null;
        // 优先判断是否在金额列表中
        if (index > -1) {
          // state.amountsList[index].isChecked = true
          state.amountsListActive = index;
          state.selectData = state.amountsList[index];
        } else {
          state.amountsListActive = -1;
          // 不在金额列表内的数据
          state.activities.forEach(item => {
            switch (item.activityType) {
              case '10':
                // 一次性充值活动
                if (!selectData) state.selectData = initOneTimeActivity(amount, item.activityType, item.oneTimeData, item.activityId);
                break;
              case '11':
                // 阶梯充值活动
                if (!selectData) state.selectData = initStepActivity(amount, item.activityType, item.stepDataList, item.activityId);
                console.log(state.selectData, 'state.selectData');
                break;
              case '14':
                // 固定额度充值活动
                if (!selectData) state.selectData = initFixedAmountActivity(amount, item.activityType, item.constantData, item.activityId);
                break;
              case '15':
                // 充值理财活动
                if (!selectData) state.selectData = initFinanceActivity(amount, item.activityType, item.timeLimitSecond, item.showTimeLimit, item.financeList, item.activityId);
                break;
              default:
            }
          });
        }
      }
    };
    /** ***函数 end*****/

    /** ***生命周期start*****/

    onMounted(() => {
      init();
    });
    /** ***生命周期end*****/

    const __returned__ = {
      t,
      appStore,
      userInfo,
      show,
      CO,
      CL,
      EC,
      MX,
      PE,
      rechargeMap,
      initLoadingShow,
      submitLoadingShow,
      state,
      timestamp,
      tabs,
      toMessage,
      handleSelectAmountChange,
      changeChecked,
      init,
      rechargeLimitHttp,
      rechargeConfigListHttp,
      initOneTimeActivity,
      initStepActivity,
      initFixedAmountActivity,
      initFinanceActivity,
      oneTimeActivity,
      stepActivity,
      fixedAmountActivity,
      financeActivity,
      initAmountsList,
      handleSubmit,
      unMountComponent,
      changeAmount,
      reactive,
      ref,
      onMounted,
      defineAsyncComponent,
      MyPopup,
      MyTabs,
      SelectAmount,
      Bubbles,
      rechargeRecordsPopup,
      rechargePopup,
      rechargeLimitApi,
      rechargeConfigListApi,
      payApi,
      myFixed,
      myWindowOpen,
      addToCart_Buried,
      useAppStore,
      useUserInfoStore,
      useI18n,
      router,
      payMethods
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};