import router from '@/router';
import { reactive, ref, watch } from 'vue';
import '@vant/touch-emulator';
/** ***引入相关包end*****/

/** ***ref、reactive，等……start*****/

export default {
  props: {
    visible: {
      type: Boolean,
      // 关闭弹窗
      default: false
    },
    callback: {
      type: Function,
      // 传入移除节点方法,这里是createApp中的方法
      default: null
    },
    gameList: {
      type: Object,
      // 传入的游戏数据
      default: () => {}
    },
    isGouterGame: {
      type: Boolean,
      // true 跳转游戏  false就不跳转
      default: true
    }
  },
  setup(__props, {
    expose
  }) {
    expose();
    const props = __props;
    /** ***引入相关包start*****/

    const data = reactive({
      howToPlay: true,
      activeIndex: 1
    });
    const isES = ref(process.env.VUE_APP_LANGUAGE_FRAME === 'es-ES');
    // 轮播图切换的索引
    const onChange = index => data.activeIndex = index + 1;
    // 定义游戏类型
    const swiperData = {
      dice: 2,
      ring: 2,
      doubleRing: 3,
      spaceDice: 3,
      digitalLottery: 3,
      pk10Lottery: 5,
      luckyLottery: 3,
      mine: 4,
      crash: 2
    };
    const pageVisible = ref(false); // 弹出框是否显示
    pageVisible.value = props.visible; // 把props参数赋值给pageVisible
    // 获取全局组件实例
    /** ***ref、reactive，等……end*****/
    /** ***函数 start*****************/
    // 跳转到游戏详情页面
    const handleGameDetail = item => {
      // 有tabId的跳转到外部游戏界面，用gameId唯一标识；否则，跳转到自研发游戏界面，用keyWord唯一标识
      if (!props.isGouterGame) {
        closePopFun();
        return;
      }
      const query = {
        gameKey: item.keyWord,
        gameName: item.gameName,
        gameId: item.id
      };
      closePopFun();
      router.push({
        path: `/game/house`,
        query
      });
    };
    // 关闭弹出框
    const closePopFun = () => {
      pageVisible.value = false;
    };
    const handleSplit = data => {
      return data === null || data === void 0 ? void 0 : data.split('\\n');
    };
    /** ***函数 end*****/

    /** ***生命周期start*****/
    // 监听显示的消失，需要移除dom
    watch(() => pageVisible.value, val => {
      !val && props.callback();
    });
    /** ***生命周期end*****/

    const __returned__ = {
      props,
      data,
      isES,
      onChange,
      swiperData,
      pageVisible,
      handleGameDetail,
      closePopFun,
      handleSplit,
      router,
      reactive,
      ref,
      watch
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};